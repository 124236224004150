import React from 'react';
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import CardText from "react-md/lib/Cards/CardText";
import Media from "react-md/lib/Media/Media";
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../../layout';

const NidoProgram = () => (
  <StaticQuery
    query={graphql`
      query {
        Image: file(relativePath: { eq: "12.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Grid stacked>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Media>
              <Img fluid={data.Image.childImageSharp.fluid} />
            </Media>
          </Cell>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Card>
              <CardTitle title="Infant Program: The Nido (3 months to 18 months)" />
              <CardText>
                <p className="md-text-justify">
                  The Nido is a warm and loving environment so your child feels
                  comfortable, secure and loved. Our program provides a variety of
                  stimulating activities through freedom of movement and choice.
                  We give children independence by placing all activities on low
                  shelves allowing them to take part if they choose. Mats, mirrors
                  and mobiles non mobile infants the opportunity to explore and
                  interact with their world. Mobile children are free to move
                  around the environment and choose activities.
                </p>
              </CardText>
            </Card>
          </Cell>
        </Grid>
      </Layout>
    )}
  />
)

export default NidoProgram;
